import React, { useState, useRef, useEffect } from "react"
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Slider from "react-slick"
// import FlexLink from '../../components/FlexLink'

import Menu from '../../components/Menu'

import cornerImg from '../../img/blossom-rock/corner-bg.png'
import mainImg from '../../img/blossom-rock/blossom-rock-bg.jpg'
import videoImg from '../../img/blossom-rock/video-poster.jpg'
import video from '../../img/blossom-rock/video.mp4'

import './index.scss'

function NextArrow({ onClick }) {
  return (
    <button
      className="arrow next"
      aria-label="Previous Slide"
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <g>
          <circle cx="50" cy="50" r="50" fill="#f39a33" />
          <polygon points="74.65 50.7 35.21 73.24 35.21 28.17 74.65 50.7" fill="#ffffff" />
        </g>
      </svg>
    </button>
  )
}

function PrevArrow({ onClick }) {
  return (
    <button
      className="arrow prev"
      aria-label="Previous Slide"
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <g>
          <circle cx="50" cy="50" r="50" fill="#f39a33" />
          <polygon points="25.35 49.3 64.79 26.76 64.79 71.83 25.35 49.3" fill="#ffffff" />
        </g>
      </svg>
    </button>
  )
}

function BlossomRock() {
  const videoRef = useRef(null)
  var sliderRef1 = useRef(null)
  var sliderRef2 = useRef(null)

  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [slides, setSlides] = useState([])
  const [playClicked, setPlayClicked] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const fetchSlides = async () => {
      const res = await axios.get(
        "https://laurel-mariposa-wp.gjstage.com/wp-json/wp/v2/pages/2?acf_format=standard"
      )
      if (res.status === 200) {
        const slideData = res.data.acf.blossom_gallery
        setSlides(slideData)
      }
    }

    fetchSlides()
  }, [])

  useEffect(() => {
    setNav1(sliderRef1)
    setNav2(sliderRef2)
  }, [slides])

  const sliderSettings1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "blossom-slider",
    infinite: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "0",
    onInit: () => {
      setNav1(sliderRef1)
    },
    beforeChange: (c, n) => {
      nav2.slickGoTo(n)
    }
  }

  const sliderSettings2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    dots: false,
    arrows: false,
    className: "blossom-big-slider",
    infinite: false,
    fade: true,
    draggable: false,
    onInit: () => {
      setNav2(sliderRef2)
    },
    beforeChange: (c, n) => {
      if (!c) {
        videoRef.current.pause()
      }
    }
  }

  function onPlayClick() {
    videoRef.current.play()
    setPlayClicked(true)
  }

  return (
    <div id='blossom-rock' className='main-wrap d-flex justify-content-end'>
      <div className='internal-wrap container'>
        <img className="corner-graphic" src={cornerImg} alt="graphic" />
        <div className="left-wrap" style={{ backgroundImage: "url(" + mainImg + ")" }}></div>
        <div className="right-wrap">
          <div className="video-wrap">
            {slides.length ?
              <Slider {...sliderSettings2} ref={slider => (sliderRef2 = slider)}>
                <div className="video">
                  <video ref={videoRef} src={video} poster={videoImg} controls={playClicked} width={1920} height={1080}></video>
                  <button className='play' onClick={onPlayClick} style={{ display: playClicked ? 'none' : 'block' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317 317">
                      <g>
                        <path d="M158.5,285.3c-69.9,0-126.8-56.9-126.8-126.8S88.6,31.7,158.5,31.7s126.8,56.9,126.8,126.8-56.9,126.8-126.8,126.8ZM158.5,0C71.01,0,0,71.01,0,158.5s71.01,158.5,158.5,158.5,158.5-71.01,158.5-158.5S245.99,0,158.5,0ZM126.8,229.82l95.1-71.32-95.1-71.32v142.65Z" />
                      </g>
                    </svg>
                  </button>
                </div>
                {slides.map((slide, i) => (
                  <div key={i} className="slide">
                    <img src={slide.url} alt={slide.alt} />
                    {/* {slide.caption ? <div className="caption">{slide.caption}</div> : null} */}
                  </div>

                ))}
              </Slider> : null}
          </div>
          <div className="gallery-wrap">
            {slides.length ?
              <Slider {...sliderSettings1} ref={slider => (sliderRef1 = slider)}>
                <div className="video slide">
                  <img src={videoImg} alt="video" />
                  <button className='play'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317 317">
                      <g>
                        <path d="M158.5,285.3c-69.9,0-126.8-56.9-126.8-126.8S88.6,31.7,158.5,31.7s126.8,56.9,126.8,126.8-56.9,126.8-126.8,126.8ZM158.5,0C71.01,0,0,71.01,0,158.5s71.01,158.5,158.5,158.5,158.5-71.01,158.5-158.5S245.99,0,158.5,0ZM126.8,229.82l95.1-71.32-95.1-71.32v142.65Z" />
                      </g>
                    </svg>
                  </button>
                </div>
                {slides.map((slide, i) => (
                  <img key={i} src={slide.url} alt={slide.alt} className="slide" />
                ))}
              </Slider> : null}
          </div>
        </div>
      </div>
      <Menu currentPage='blossom-rock' />
      {/* <Modal centered show={show} onHide={() => setShow(false)} dialogClassName="gallery-modal">
        <Modal.Body>
          {slides.length ?
            <Slider  {...sliderSettings2}>
              {slides.map((slide, i) => (
                <div  key={i} className="slide">
                  <img src={slide.url} alt={slide.alt} />
                  {slide.caption ? <div className="caption">{slide.caption}</div> : null}
                </div>
              ))}
            </Slider> : null}
          <button className="close" onClick={() => setShow(false)}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </button>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export default BlossomRock
