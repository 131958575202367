import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useIdle } from 'react-use'
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'

import App from './containers/App';
import SiteMap from './containers/SiteMap';
import AroundHere from './containers/AroundHere';
import Neighborhoods from './containers/Neighborhoods';
import BlossomRock from './containers/BlossomRock';

import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Wrapper />
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
)

function Wrapper() {
  const isIdle = useIdle(300e3, true)
  const navigate = useNavigate()

  const [neighborhoods, setNeighborhoods] = useState([])
  const [plans, setPlans] = useState([])

  useEffect(() => {
    let neighborhoodsData = sessionStorage.getItem('neighborhoods')
    let plansData = sessionStorage.getItem('plans')

    if (neighborhoodsData) {
      neighborhoodsData = JSON.parse(neighborhoodsData)
      setNeighborhoods(neighborhoodsData.reverse())
    } else {
      fetch('https://laurel-mariposa-wp.gjstage.com/wp-json/wp/v2/neighborhood')
      .then(response => {
        return response.json()
      })
      .then(data => {
        setNeighborhoods(data.reverse())
        sessionStorage.setItem('neighborhoods', JSON.stringify(data.reverse()))
      });
    }

    if (plansData) {
      plansData = JSON.parse(plansData)
      setPlans(plansData.reverse())
    } else {
      fetch('https://laurel-mariposa-wp.gjstage.com/wp-json/wp/v2/plan')
      .then(response => {
        return response.json()
      })
      .then(data => {
        setNeighborhoods(data.reverse())
        sessionStorage.setItem('plans', JSON.stringify(data.reverse()))
      });
    }
    if (isIdle) {
      navigate('/', { replace: true })
    }
  }, [isIdle, navigate])

  return (
    <Routes>
      <Route path='/' element={<App isIdle={isIdle} />} />
      <Route path='neighborhoods/:hood/*' element={<Neighborhoods />}/>
      <Route path='neighborhoods/:hood/:sub/*' element={<Neighborhoods />}/>
      <Route path='neighborhoods/:hood/:sub/:plan' element={<Neighborhoods />}/>
      <Route path='site-map' element={<SiteMap neighborhoods={neighborhoods} plans={plans} />} />
      <Route path='around-here' element={<AroundHere />} />
      <Route path='blossom-rock' element={<BlossomRock />} />
    </Routes>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
