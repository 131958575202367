import React from 'react';
// import { Link, useParams } from 'react-router-dom';

import FlexLink from '../FlexLink';

import personalize from '../../img/neighborhoods/personalize-your-home.png'
import click from '../../img/neighborhoods/click-to-personalize.png'

import './index.scss';

function SideMenu(props) {
  
  // let params = useParams();
  const planSlug = props.plan.title.toLowerCase();
  // const plan1Slug = props.plan.plans[0].title.toLowerCase().split(' ').join('-');
  const options = props.plan.options;
  
  return (
    <div id='side-menu' className={`${planSlug} d-flex flex-column justify-content-evenly text-center`}>
      {/* <img className='hood-logo img-responsive mx-auto my-3 mb-xl-5' src={props.hood.logo} alt={props.hood.title}/> */}
      <img className='personalize w-100 mx-auto' src={personalize} alt='Personalize Your Home' width='315'/>
      <div className='options-title'>Available Options</div>
      <ul className='options'>
        {
          options.map((item, i) => {
            return <li key={i}>{item}</li>
          })
        }
      </ul>
      <FlexLink className='btn px-5' to={props.plan.link} anchors>
        <img className='' src={click} alt='Personalize Your Home' width='98'/>
      </FlexLink>
    </div>
  )
}

export default SideMenu;
