import React from 'react';

import Menu from '../../components/Menu';

import shapes from '../../img/home/home-shapes.png'
import lockup from '../../img/home/home-title-lockup.png'

import './index.scss';

function Home() {

  return (
    <div id='home' className='main-wrap'>
      <div className='internal-wrap d-flex flex-column align-items-center'>
        <img className='shapes' src={shapes} aria-hidden='true' alt=''/>
        <img className='lockup' src={lockup} width='964' alt='Your Journey Starts Here | Laurel + Mariposa at Blossom Rock'/>
      </div>
      <Menu currentPage='home'/>
    </div>
  )
}

export default Home;
