import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'

import SingleFloorPlan from '../SingleFloorPlan'

import './index.scss'

function Hood(props) {
  console.log('hood plans: ', props.hood.plans);
  const [activeKey, setActiveKey] = useState(0);
  let params = useParams();
  const hoodSlug = props.hood.title.toLowerCase();
  const activePlan = props.hood.plans.find( plan => plan.title.toLowerCase().split(' ').join('-') === params.plan);
  console.log('params: ', params);
  console.log('hood active plan: ', activePlan);

  return (
    <div id='hood' className='hood-details flex-fill d-flex flex-column'>
      <div id='hood-title-bar' className={`container pt-3 pb-4 ${hoodSlug}`}>
        <div className='row align-items-center'>
          <div className='col-auto p-0'>
            <div className='logo-wrap'>
              <img className='hood-logo py-3' src={props.hood.logo} alt={props.hood.title}/>
            </div>
          </div>
          <div className='specs-plan-wrap col p-0 d-flex justify-content-between align-items-end'>
            <div className='active-plan'>
              <div className='title'>{activePlan.title} <span className='number'>{activePlan.number}</span></div>
              <div className='plan-specs'>
                {activePlan.stories} • {activePlan.sqft} Sq. Ft.<br/>
                {activePlan.beds} Bedrooms • {activePlan.baths} Baths<br/>
                {activePlan.extras.map((item, i) => i === 0 ? item : ` •  ${item}`)} • {activePlan.garage}
              </div>
            </div>
            <div className='plan-nav mb-3'>
              <ul>
                {
                  props.hood.plans.map((plan, i) => {
                    const planSlug = plan.title.toLowerCase().split(' ').join('-');
                    return <li key={i}><Link to={`/neighborhoods/${hoodSlug}/floor-plans/${planSlug}`} className={activeKey === i ? 'active' : ''} onClick={() => setActiveKey(i)}>{plan.title} <span className='number'>{plan.number}</span></Link></li>
                  })
                }
              </ul>
            </div>
            {/* <div className='plan-specs'>
              {activePlan.stories} • {activePlan.sqft} Sq. Ft.<br/>
              {activePlan.beds} Bedrooms • {activePlan.baths} Baths<br/>
              {activePlan.extras.map((item, i) => i === 0 ? item : ` •  ${item}`)} • {activePlan.garage}
            </div> */}
          </div>
        </div>
      </div>
      <SingleFloorPlan plan={activePlan}/>
    </div>
  )
}

export default Hood
