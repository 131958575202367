import React from 'react'

import video from '../../img/screensaver.mp4'

import Home from '../Home'

import './index.scss'

// console.log('ratio: ', window.innerHeight / window.innerWidth);

let videoClass = window.innerHeight / window.innerWidth > 0.5624 ? 'w-100' : 'h-100';

function App({isIdle}) {

  return (
    <div id='app'>
      {isIdle ? (
        <div className='d-flex justify-content-center align-items-center'>
          <video
            className={videoClass}
            src={video}
            autoPlay={true}
            width='1920'
            height='1080'
            preload='auto'
            loop={true}
            muted
          ></video>
        </div>
      ) : (
        <Home />
      )}
    </div>
  )
}

export default App
