import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  to: PropTypes.string.isRequired,
  anchors: PropTypes.bool
};

/**
 * Link that also works for external URL's
 */
export default class FlexLink extends Component {
  render() {
    return this.props.anchors ?
      <a
        href={this.props.to}
        children={this.props.children}
        className={this.props.className}
        target="_blank"
        rel="noreferrer"
        // Individual props instead of {...this.props} to prevent console warnings for anchors prop/attribute
      />
      :
      <Link {...this.props} />;
  }
}

FlexLink.propTypes = propTypes;