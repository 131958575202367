import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom'

import neighborhoods from '../../neighborhoods';

import Menu from '../../components/Menu';
// import SideMenu from '../../components/SideMenu';
import Hood from '../../components/Hood';
// import Elevations from '../../components/Elevations';
// import Features from '../../components/Features';
// import FloorPlans from '../../components/FloorPlans';
// import Gallery from '../../components/Gallery';
// import SiteMap from '../../components/SiteMap';
// import Team from '../../components/Team';

import './index.scss'

function Neighborhoods() {
  
  let params = useParams();
  const activeHood = neighborhoods.find(item => item.title.toLowerCase() === params.hood);

  // let activeSubComponent;
  
  // switch (params.sub) {
  //   case '':
  //     activeSubComponent = <Hood hood={activeHood}/>
  //     break;
  //   case 'floor-plans':
  //     activeSubComponent = <FloorPlans hood={activeHood}/>
  //     break;
  //   case 'elevations':
  //     activeSubComponent = <Elevations hood={activeHood}/>
  //     break;
  //   case 'site-map':
  //     activeSubComponent = <SiteMap hood={activeHood}/>
  //     break;
  //   case 'features':
  //     activeSubComponent = <Features hood={activeHood}/>
  //     break;
  //   case 'gallery':
  //     activeSubComponent = <Gallery hood={activeHood}/>
  //     break;
  //   case 'team':
  //     activeSubComponent = <Team hood={activeHood}/>
  //     break;
  //   default:
  //     activeSubComponent = <Hood hood={activeHood}/>
  //     break;
  // }

  return (
    <div id='neighborhoods' className='main-wrap'>
      <div id='hood-wrap' className='internal-wrap d-flex w-100'>
        <Hood hood={activeHood}/>
        {/* <SideMenu hood={activeHood} currentPage='overview'/> */}
      </div>
      <Menu currentPage='neighborhoods'/>
      <Routes>
        <Route path=":hood/floor-plans/:plan" element={<Hood />}>
          {/* <Route path=':hood/floor-plans/:plan' element={<SingleFloorPlan />} /> */}
        </Route>
      </Routes>
    </div>
  )
}

export default Neighborhoods
