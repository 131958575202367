import LaurelLogo from './img/neighborhoods/laurel/laurel-logo-white.png'

import LantanaFloorPlan from './img/neighborhoods/laurel/lantana-floor-plan.png'
import LantanaElevationC from './img/neighborhoods/laurel/lantana-C-spanish-colonial.jpg'
import LantanaElevationD from './img/neighborhoods/laurel/lantana-D-arizona-ranch.jpg'
import LantanaElevationE from './img/neighborhoods/laurel/lantana-E-traditional-southwest.jpg'
import LantanaElevationF from './img/neighborhoods/laurel/lantana-F-contemporary-farmhouse.jpg'

import LaredoFloorPlan from './img/neighborhoods/laurel/laredo-floor-plan.png'
import LaredoElevationC from './img/neighborhoods/laurel/laredo-C-spanish-colonial.jpg'
import LaredoElevationD from './img/neighborhoods/laurel/laredo-D-arizona-ranch.jpg'
import LaredoElevationE from './img/neighborhoods/laurel/laredo-E-traditional-southwest.jpg'
import LaredoElevationF from './img/neighborhoods/laurel/laredo-F-contemporary-farmhouse.jpg'

import PonderosaFloorPlan1 from './img/neighborhoods/laurel/ponderosa-floor-plan-floor-1.png'
import PonderosaFloorPlan2 from './img/neighborhoods/laurel/ponderosa-floor-plan-floor-2.png'
import PonderosaElevationC from './img/neighborhoods/laurel/ponderosa-C-spanish-colonial.jpg'
import PonderosaElevationD from './img/neighborhoods/laurel/ponderosa-D-arizona-ranch.jpg'
import PonderosaElevationE from './img/neighborhoods/laurel/ponderosa-E-traditional-southwest.jpg'
import PonderosaElevationF from './img/neighborhoods/laurel/ponderosa-F-contemporary-farmhouse.jpg'

import HeritageFloorPlan1 from './img/neighborhoods/laurel/heritage-floor-plan-floor-1.png'
import HeritageFloorPlan2 from './img/neighborhoods/laurel/heritage-floor-plan-floor-2.png'
import HeritageElevationC from './img/neighborhoods/laurel/heritage-C-spanish-colonial.jpg'
import HeritageElevationD from './img/neighborhoods/laurel/heritage-D-arizona-ranch.jpg'
import HeritageElevationE from './img/neighborhoods/laurel/heritage-E-traditional-southwest.jpg'
import HeritageElevationF from './img/neighborhoods/laurel/heritage-F-contemporary-farmhouse.jpg'

import MariposaLogo from './img/neighborhoods/mariposa/mariposa-logo-white.png'

import AgaveFloorPlan from './img/neighborhoods/mariposa/agave-floor-plan.png'
import AgaveElevationC from './img/neighborhoods/mariposa/agave-C-spanish-colonial.jpg'
import AgaveElevationD from './img/neighborhoods/mariposa/agave-D-arizona-ranch.jpg'
import AgaveElevationE from './img/neighborhoods/mariposa/agave-E-traditional-southwest.jpg'
import AgaveElevationF from './img/neighborhoods/mariposa/agave-F-contemporary-farmhouse.jpg'

import LilyFloorPlan from './img/neighborhoods/mariposa/lily-floor-plan.png'
import LilyElevationC from './img/neighborhoods/mariposa/lily-C-spanish-colonial.jpg'
import LilyElevationD from './img/neighborhoods/mariposa/lily-D-arizona-ranch.jpg'
import LilyElevationE from './img/neighborhoods/mariposa/lily-E-traditional-southwest.jpg'
import LilyElevationF from './img/neighborhoods/mariposa/lily-F-contemporary-farmhouse.jpg'

import SolsticeFloorPlan from './img/neighborhoods/mariposa/solstice-floor-plan.png'
import SolsticeElevationC from './img/neighborhoods/mariposa/solstice-C-spanish-colonial.jpg'
import SolsticeElevationD from './img/neighborhoods/mariposa/solstice-D-arizona-ranch.jpg'
import SolsticeElevationE from './img/neighborhoods/mariposa/solstice-E-traditional-southwest.jpg'
import SolsticeElevationF from './img/neighborhoods/mariposa/solstice-F-contemporary-farmhouse.jpg'

import CanyonFloorPlan1 from './img/neighborhoods/mariposa/canyon-floor-plan-floor-1.png'
import CanyonFloorPlan2 from './img/neighborhoods/mariposa/canyon-floor-plan-floor-2.png'
import CanyonElevationC from './img/neighborhoods/mariposa/canyon-C-spanish-colonial.jpg'
import CanyonElevationD from './img/neighborhoods/mariposa/canyon-D-arizona-ranch.jpg'
import CanyonElevationE from './img/neighborhoods/mariposa/canyon-E-traditional-southwest.jpg'
import CanyonElevationF from './img/neighborhoods/mariposa/canyon-F-contemporary-farmhouse.jpg'

import SkylarFloorPlan1 from './img/neighborhoods/mariposa/skylar-floor-plan-floor-1.png'
import SkylarFloorPlan2 from './img/neighborhoods/mariposa/skylar-floor-plan-floor-2.png'
import SkylarElevationC from './img/neighborhoods/mariposa/skylar-C-spanish-colonial.jpg'
import SkylarElevationD from './img/neighborhoods/mariposa/skylar-D-arizona-ranch.jpg'
import SkylarElevationE from './img/neighborhoods/mariposa/skylar-E-traditional-southwest.jpg'
import SkylarElevationF from './img/neighborhoods/mariposa/skylar-F-contemporary-farmhouse.jpg'

const neighborhoods = [
  {
    title: "Laurel",
    logo: LaurelLogo,
    color: '#F39A33',
    statuses: [
      'View All',
      'Available',
      'Sold',
      'Unreleased'
    ],
    releases: [
      'View All',
      'Phase 1',
      'Phase 2'
    ],
    plans: [
      {
        community: "Laurel",
        color: '#F39A33',
        title: "Lantana",
        number: '4010',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/laurel/lantana?visualizer=floorplan',
        stories: 'One-Story',
        sqft: '1,998',
        beds: '3 – 4',
        baths: '2',
        extras: [
          'Flex Room',
          'Outdoor Room'
        ],
        garage: '2-Car Garage',
        floorPlans: [LantanaFloorPlan],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: LantanaElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: LantanaElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: LantanaElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: LantanaElevationF
          }
        ],
        options: [
          'Opt. Bed 4 in lieu of Flex Room',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. Outdoor Room extension',
          'Opt. 2nd sink at Bath 2'
        ]
      },
      {
        community: "Laurel",
        color: '#F39A33',
        title: "Laredo",
        number: '4020R',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/laurel/laredo?visualizer=floorplan',
        stories: 'One-Story',
        sqft: '2,175 – 2,354',
        beds: '4 – 5',
        baths: '2 – 3',
        extras: [
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [LaredoFloorPlan],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: LaredoElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: LaredoElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: LaredoElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: LaredoElevationF
          }
        ],
        options: [
          'Opt. Bed 5 with Bath 3 in lieu of Tandem Garage',
          'Opt. Flex Room in lieu of Bed 4',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. Outdoor Room extension'
        ]
      },
      {
        community: "Laurel",
        color: '#F39A33',
        title: "Ponderosa",
        number: '4030R',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/laurel/ponderosa?visualizer=floorplan',
        stories: 'Two-Story',
        sqft: '2,801 – 3,121',
        beds: '4 – 5',
        baths: '3 – 4',
        extras: [
          'Flex Room',
          'Bonus Room',
          'Outdoor Room',
          'Optional Multi-Gen Suite'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [PonderosaFloorPlan1, PonderosaFloorPlan2],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: PonderosaElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: PonderosaElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: PonderosaElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: PonderosaElevationF
          }
        ],
        options: [
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. Multi-Gen Suite in lieu of Flex Room and Tandem Garage',
          'Opt. sliding glass door at Outdoor Room',
          'Opt. Outdoor Room extension',
          'Opt. 2nd sink at Bath 2'
        ]
      },
      {
        community: "Laurel",
        color: '#F39A33',
        title: "Heritage",
        number: '4040',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/laurel/heritage?visualizer=floorplan',
        stories: 'Two-Story',
        sqft: '3,094',
        beds: '5 – 6',
        baths: '3.5',
        extras: [
          'Tech Space',
          'Bonus Room',
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [HeritageFloorPlan1, HeritageFloorPlan2],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: HeritageElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: HeritageElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: HeritageElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: HeritageElevationF
          }
        ],
        options: [
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. Outdoor Room extension',
          'Opt. sliding glass door at Outdoor Room',
          'Opt. Bed 6 in lieu of Bonus Room',
          'Opt. 2nd sink at Bath 2'
        ]
      }
    ],
  },
  {
    title: "Mariposa",
    logo: MariposaLogo,
    color: '#00A6CF',
    statuses: [
      'View All',
      'Available',
      'Sold',
      'Unreleased'
    ],
    releases: [
      'View All',
      'Phase 1',
      'Phase 2'
    ],
    plans: [
      {
        community: "Mariposa",
        color: '#00A6CF',
        title: "Agave",
        number: '5010',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/mariposa/agave?visualizer=floorplan',
        stories: 'One-Story',
        sqft: '2,363 – 2,575',
        beds: '3 – 5',
        baths: '2 – 3.5',
        extras: [
          'Flex Room',
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [AgaveFloorPlan],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: AgaveElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: AgaveElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: AgaveElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: AgaveElevationF
          }
        ],
        options: [
          'Opt. Bed 4 in lieu of Flex Room',
          'Opt. Bed 5 and Bath 3 in lieu of Tandem Garage',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. 2nd sink at Bath 2',
          'Opt. Powder Room in lieu of Linen'
        ]
      },
      {
        community: "Mariposa",
        color: '#00A6CF',
        title: "Lily",
        number: '5020R',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/mariposa/lily?visualizer=floorplan',
        stories: 'One-Story',
        sqft: '2,586 – 2,760',
        beds: '4 – 5',
        baths: '2 – 3',
        extras: [
          'Flex Room',
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [LilyFloorPlan],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: LilyElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: LilyElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: LilyElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: LilyElevationF
          }
        ],
        options: [
          'Opt. Bed 5 and Bath 3 in lieu of Tandem Garage',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. 2nd sink at Bath 2',
          'Opt. Powder Room in lieu of opt. Linen'
        ]
      },
      {
        community: "Mariposa",
        color: '#00A6CF',
        title: "Solstice",
        number: '5030R',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/mariposa/solstice?visualizer=floorplan',
        stories: 'One-Story',
        sqft: '2,747 – 2,895',
        beds: '3 – 5',
        baths: '3 – 4.5',
        extras: [
          'Flex Room',
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [SolsticeFloorPlan],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: SolsticeElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: SolsticeElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: SolsticeElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: SolsticeElevationF
          }
        ],
        options: [
          'Opt. Bed 5 and Bath 4 in lieu of Tandem Garage',
          'Opt. Multi-Gen Suite in lieu of Bed 3 and Bed 4',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. 2nd sink at Bath 3',
          'Opt. Powder Room in lieu of Linen'
        ]
      },
      {
        community: "Mariposa",
        color: '#00A6CF',
        title: "Canyon",
        number: '5040',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/mariposa/canyon?visualizer=floorplan',
        stories: 'Two-Story',
        sqft: '3,110 – 3,898',
        beds: '4 – 7',
        baths: '3 – 4',
        extras: [
          'Flex Room',
          'Bonus Room',
          'Outdoor Room'
        ],
        garage: '3-Car Tandem Garage',
        floorPlans: [CanyonFloorPlan1, CanyonFloorPlan2],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: CanyonElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: CanyonElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: CanyonElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: CanyonElevationF
          }
        ],
        options: [
          'Opt. Hobby Room in lieu of Tandem Garage',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. sliding glass door at Outdoor Room',
          'Opt. Bed 5 in lieu of Bonus Room',
          'Opt. Bed 6 and Bed 7 with Bath 4',
          'Opt. 2nd Bonus Room',
          'Opt. Super Bonus Room with Bed 6 and Bed 7 with Bath 4'
        ]
      },
      {
        community: "Mariposa",
        color: '#00A6CF',
        title: "Skylar",
        number: '5050',
        link: 'https://www.brookfieldresidential.com/new-homes/arizona/phoenix/apache-junction/blossom-rock/mariposa/skylar?visualizer=floorplan',
        stories: 'Two-Story',
        sqft: '3,488 – 4,198',
        beds: '5 – 8',
        baths: '3.5 – 5.5',
        extras: [
          'Flex Room',
          'Office',
          'Bonus Room',
          'Outdoor Room'
        ],
        garage: '4-Car Tandem Garage',
        floorPlans: [SkylarFloorPlan1, SkylarFloorPlan2],
        elevations: [
          {
            title: 'C – Spanish Colonial',
            img: SkylarElevationC
          },
          {
            title: 'D – Arizona Ranch',
            img: SkylarElevationD
          },
          {
            title: 'E – Traditional Southwest',
            img: SkylarElevationE
          },
          {
            title: 'F – Contemporary Farmhouse',
            img: SkylarElevationF
          }
        ],
        options: [
          'Opt. Bed 6 and Bath 4 in lieu of Flex Room and Office',
          'Opt. Gourmet Kitchen or Deluxe Gourmet Kitchen',
          'Opt. sliding glass door at Outdoor Room',
          'Opt. Outdoor Room extension',
          'Opt. 2nd Bonus Room',
          'Opt. Bed 7 and Bed 8 with Bath 5',
          'Opt. Super Bonus Room with Bed 7 and Bed 8 with Bath 5'
        ]
      }
    ],
  }
]

export default neighborhoods
