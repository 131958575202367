import React from 'react';
import { Link, useParams } from 'react-router-dom';

import neighborhoods from '../../neighborhoods';
// import FlexLink from '../FlexLink';

import logo from '../../img/laurel-mariposa-logo.svg';
import brLogo from '../../img/brookfield-residential-logo-white.svg';

import './index.scss';

function Menu(props) {

  let params = useParams();

  return (
    <div id='menu'>
      <ul className='d-flex justify-content-around align-items-center w-100 h-100'>
        <li className='logo'><Link to='/'><img height='68' src={logo} alt='Laurel + Mariposa at Blossom Rock' /></Link></li>
        <li className='has-sub-menu'>
          <Link to='#' className={props.currentPage === 'neighborhoods' ? 'active' : ''}><span>Floorplans</span></Link>
          <ul className='sub-menu d-flex flex-column justify-content-center align-items-center'>
            {
              neighborhoods.map((hood, i) => {
                const hoodSlug = hood.title.toLowerCase();
                // let firstPlan = hood.plans.map( plan => plan[0].title.toLowerCase().split(' ').join('-'));
                // console.log('firstPlan: ', firstPlan);
                return <li key={i}><Link to={`/neighborhoods/${hoodSlug}/floor-plans/${hood.plans[0].title.toLowerCase()}`} className={params.hood === hoodSlug ? 'active' : ''}><span>{hood.title}</span></Link></li>
              })
            }
          </ul>
        </li>
        <li><Link to='/site-map' className={props.currentPage === 'site-map' ? 'active' : ''}><span>Site Map</span></Link></li>
        <li><Link to='/around-here' className={props.currentPage === 'around-here' ? 'active' : ''}><span>Around Here</span></Link></li>
        <li><Link to='/blossom-rock' className={props.currentPage === 'blossom-rock' ? 'active' : ''}><span>Blossom Rock</span></Link></li>
        <li className='br-logo'><Link to='/'><img height='68' src={brLogo} alt='Brookfield Residential' /></Link></li>
      </ul>
    </div>
  )
}

export default Menu;
