import React, { useState } from 'react'
import { /*ButtonGroup, Dropdown, DropdownButton,*/ Form } from 'react-bootstrap'

import Menu from '../../components/Menu'

import FindYourHome from '../../img/site-map/find-your-home.png'
import MapImg from '../../img/site-map/site-map-bg.jpg'

import './index.scss'

function SiteMap(props) {

  const lots = setLotData(props.neighborhoods);
  const [laurelStatus, setLaurelStatus] = useState('status')
  const [laurelRelease, setLaurelRelease] = useState('release')
  const [mariposaStatus, setMariposaStatus] = useState('status')
  const [mariposaRelease, setMariposaRelease] = useState('release')

  function setLotData(data) {
    let arr = [];

    data.map(item => {
      
      return (
        item.acf.lots.map(l => {
            let lot = {};
            lot.neighborhood = item.slug;
            lot.number = l.number;
            lot.status = l.status;
            lot.release = l.release;
            lot.plan = l.plan;
            lot.elevation = l.elevation;
            lot.elevation_right = l.elevation_right;
            lot.shape_polygon = l.shape_polygon;
            lot.svg_code = l.svg_code;
            lot.shape_path = l.shape_path;
            lot.model = l.model;
            lot.sales_office = l.sales_office;
            return arr.push(lot);
          })
      )
    });
    return arr;
  }

  function setCenter(coordString) {
    // Get the center coordinates of the polygon
    var coords = coordString.split(' ');
    // console.log('coords: ', coords);
    var posXmax,posYmax,posXmin,posYmin;
    for ( var a = 0, l = coords.length; a < l; a += 2 ) {
      if (a === 0) {
        posXmin = Number(coords[a]);
        posXmax = Number(coords[a]);
        posYmin = Number(coords[a+1]);
        posYmax = Number(coords[a+1]);
      } else {
        if (Number(coords[a]) < posXmin) {
          posXmin = Number(coords[a]);
        }
        if (Number(coords[a+1]) < posYmin) {
          posYmin = Number(coords[a+1]);
        }
        if (Number(coords[a]) > posXmax) {
          posXmax = Number(coords[a]);
        }
        if (Number(coords[a+1]) > posYmax) {
          posYmax = Number(coords[a+1]);
        }
      }
    }
    // console.log('posXmax: ', posXmax);
    // Set position to center
    return {x: (posXmax + posXmin) / 2, y: (posYmax + posYmin) / 2};
  }

  function viewAll() {
    setLaurelStatus('view-all')
    setLaurelRelease('view-all')
    setMariposaStatus('view-all')
    setMariposaRelease('view-all')
  }

  const neighborhoods = props.neighborhoods;
  const plans = props.plans;
  // const testCenter = setCenter('430.31 769.46 335.99 744.12 345.71 720.57 430.31 769.46');
  // console.log('testCenter: ', testCenter);
  // console.log('lots: ', lots);
  // console.log('plans: ', plans);

  return (
    <div id='site-map' className='main-wrap'>
      <div className='internal-wrap container'>
        <div className='row h-100'>
          <div className='left-side col-md-3 d-flex flex-column justify-content-center align-items-center'>
            <img className='mb-3' src={FindYourHome} alt='Find Your Home' width='295'/>
            <button className='view-all btn mb-4' onClick={viewAll}>View All</button>
            <form className='w-100'>
              <div className='filter-block w-100'>
                {
                  neighborhoods && neighborhoods.map((hood, i) => {
                    return (
                      <fieldset key={i} className='hood-filter-wrap'>
                        <legend className={`section-title ${hood.slug}`}>{hood.slug.charAt(0).toUpperCase() + hood.slug.substr(1).toLowerCase()}</legend>
                        <div className='btn-wrap d-flex justify-content-between'>
                          <Form.Select
                          className='mx-2 mb-3'
                          aria-label="Choose status"
                          value={i === 0 ? laurelStatus : mariposaStatus}
                          onChange={(e) => i === 0 ? setLaurelStatus(e.target.value) : setMariposaStatus(e.target.value)}>
                            <option value='status'>Status</option>
                            <option value='view-all'>View All</option>
                            <option value='unreleased'>Unreleased</option>
                            <option value='available'>Available</option>
                            <option value='sold'>Sold</option>
                          </Form.Select>
                          <Form.Select
                          className='mx-2 mb-3'
                          aria-label="Choose release"
                          value={i === 0 ? laurelRelease : mariposaRelease}
                          onChange={(e) => i === 0 ? setLaurelRelease(e.target.value) : setMariposaRelease(e.target.value)}>
                            <option value='release'>Release</option>
                            <option value='view-all'>View All</option>
                            <option value='release1'>Release 1</option>
                            <option value='release2'>Release 2</option>
                          </Form.Select>
                          {/* <DropdownButton
                            className='mb-3'
                              as={ButtonGroup}
                              id={`dropdown-button-drop-${hoodSlug}-status`}
                              drop='end'
                              variant="secondary"
                              title='Status'
                            >
                              {hood.statuses.map((status, i) => <Dropdown.Item key={i} eventKey={i}>{status}</Dropdown.Item>)}
                            </DropdownButton>
                            <DropdownButton
                              className='mb-3'
                              as={ButtonGroup}
                              id={`dropdown-button-drop-${hoodSlug}-release`}
                              drop='end'
                              variant="secondary"
                              title='Release'
                            >
                              {hood.releases.map((release, i) => <Dropdown.Item key={i} eventKey={i}>{release}</Dropdown.Item>)}
                            </DropdownButton> */}
                        </div>
                      </fieldset>
                    )
                  })
                }
              </div>
              <fieldset className='legend-block w-100 px-3'>
                <legend className='section-title mb-2'>Legends</legend>
                <div className='section-subtitle mb-2'>Neighborhoods</div>
                <div className='legend-block row'>
                  {
                    neighborhoods && neighborhoods.map((hood, i) => {
                      return (
                        <div key={i} className='legend-hood col-md-6'>
                          <div className='legend-item d-flex mb-2'><span className='color-block' style={{backgroundColor: hood.acf.color}}></span> {hood.slug.charAt(0).toUpperCase() + hood.slug.substr(1).toLowerCase()}</div>
                          {
                            plans && plans.map((plan, i) => {
                              return(
                                plan.acf.neighborhood === hood.id
                                ?
                                <div key={i} className='legend-item ms-4 d-flex mb-2'><span className='color-block' style={{backgroundColor: plan.acf.color}}></span> {plan.slug.charAt(0).toUpperCase() + plan.slug.substr(1).toLowerCase()}</div>
                                :
                                ''
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className='section-subtitle mb-1'>Architectural Style</div>
                <div className='legend-block d-flex'>
                  <ul className='pt-0'>
                    <li className='legend-item mb-2'>C – Spanish Colonial</li>
                    <li className='legend-item mb-2'>D – Arizona Ranch</li>
                    <li className='legend-item mb-2'>E – Traditional Southwest</li>
                    <li className='legend-item mb-2'>F – Contemporary Farmhouse</li>
                  </ul>
                </div>
              </fieldset>
            </form>
          </div>
          <div className='col-md-9 h-100 p-0 d-flex justify-content-end'>
            <div id='map-wrap'>
              <style>
                {
                  plans && plans.map(plan => {
                    return (
                        // {`g.plan-${plan.id} > path, g.plan-${plan.id} > polygon { fill: ${plan.acf.color} !important; }`}
                        `g.plan-`+plan.id+` > path, g.plan-`+plan.id+` > polygon { fill: `+plan.acf.color+` !important; }`
                    )
                  })
                }
              </style>
              <img className='map-img h-100' src={MapImg} alt='community map'/>
              <svg id="shapes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1208.47 920">
                <g id="laurel-overlay">
                  <polygon className="st0" points="430.31 769.46 335.99 744.12 345.71 720.57 430.31 769.46"/>
                  <circle className="st0" cx="353.21" cy="733.18" r="53.94"/>
                  <g>
                    <path className="st1" d="M321.84,726.47h1.58v12.92h8.7v1.45h-10.28v-14.37Z"/>
                    <path className="st1" d="M332.42,735.47c0-3.38,2.42-5.78,5.84-5.78s5.82,2.35,5.82,5.78v5.36h-1.36v-2.68c-.77,1.93-2.57,2.97-4.68,2.97-3.05,0-5.62-2.17-5.62-5.65ZM342.61,735.41c0-2.5-1.76-4.35-4.35-4.35s-4.35,1.85-4.35,4.35,1.76,4.35,4.35,4.35,4.35-1.85,4.35-4.35Z"/>
                    <path className="st1" d="M345.85,735.65v-5.67h1.47v5.67c0,2.66,1.54,4.11,3.89,4.11s3.89-1.45,3.89-4.11v-5.67h1.47v5.67c0,3.6-2.15,5.47-5.36,5.47s-5.36-1.87-5.36-5.47Z"/>
                    <path className="st1" d="M358.43,734.33c0-3.12,1.87-4.64,4.57-4.64s4.55,1.52,4.55,4.66v.13h-1.45v-.15c0-2.26-1.25-3.27-3.1-3.27s-3.1,1.01-3.1,3.27v6.5h-1.47v-6.5Z"/>
                    <path className="st1" d="M368.37,735.41c0-3.3,2.42-5.71,5.84-5.71s5.8,2.42,5.8,5.71v.48h-10.13c.2,2.26,1.89,3.87,4.33,3.87,1.71,0,3.03-.7,3.73-1.96h1.6c-.83,2-2.81,3.32-5.34,3.32-3.43,0-5.84-2.42-5.84-5.71ZM378.47,734.57c-.35-2.2-2.07-3.51-4.26-3.51s-3.95,1.38-4.28,3.51h8.55Z"/>
                    <path className="st1" d="M381.68,725.24h1.47v15.6h-1.47v-15.6Z"/>
                  </g>
                </g>
                <g id="mariposa-overlay">
                  <polygon className="st11" points="407.07 247.7 309.87 238.23 315.58 213.41 407.07 247.7"/>
                  <circle className="st11" cx="287.06" cy="216.1" r="53.94"/>
                  <g>
                    <path className="st1" d="M239.2,210.57c0-3.37,2.12-5.06,4.88-5.06,2.01,0,3.57.93,4.3,2.65.74-1.71,2.3-2.65,4.3-2.65,2.77,0,4.88,1.69,4.88,5.06v9.42h-1.61v-9.42c0-2.41-1.45-3.63-3.39-3.63s-3.39,1.28-3.39,3.63v9.42h-1.61v-9.42c0-2.34-1.43-3.63-3.39-3.63s-3.39,1.22-3.39,3.63v9.42h-1.61v-9.42Z"/>
                    <path className="st1" d="M269.76,214.64c0-2.48-1.78-4.3-4.42-4.3s-4.41,1.82-4.41,4.3,1.78,4.3,4.41,4.3,4.42-1.82,4.42-4.3M259.41,214.7c0-3.34,2.45-5.71,5.93-5.71s5.91,2.32,5.91,5.71v5.3h-1.38v-2.65c-.78,1.91-2.61,2.93-4.75,2.93-3.1,0-5.71-2.15-5.71-5.58"/>
                    <path className="st1" d="M273.26,213.57c0-3.08,1.9-4.58,4.64-4.58s4.62,1.5,4.62,4.6v.13h-1.47v-.15c0-2.24-1.27-3.24-3.14-3.24s-3.14,1-3.14,3.24v6.43h-1.49v-6.43Z"/>
                    <path className="st1" d="M283.79,209.27h1.49v10.73h-1.49v-10.73ZM283.39,206.08c0-.63.49-1.11,1.14-1.11s1.14.48,1.14,1.11-.49,1.11-1.14,1.11-1.14-.48-1.14-1.11"/>
                    <path className="st1" d="M297.72,214.64c0-2.48-1.78-4.3-4.42-4.3s-4.42,1.82-4.42,4.3,1.76,4.3,4.42,4.3,4.42-1.82,4.42-4.3M287.4,214.7c0-3.45,2.48-5.71,5.89-5.71s5.95,2.39,5.95,5.69-2.48,5.6-5.71,5.6c-2.1,0-3.81-.96-4.64-2.58v6.88h-1.49v-9.88Z"/>
                    <path className="st1" d="M310.6,214.64c0-2.48-1.78-4.3-4.42-4.3s-4.42,1.82-4.42,4.3,1.78,4.3,4.42,4.3,4.42-1.82,4.42-4.3M300.25,214.64c0-3.26,2.45-5.65,5.93-5.65s5.93,2.39,5.93,5.65-2.45,5.65-5.93,5.65-5.93-2.39-5.93-5.65"/>
                    <path className="st1" d="M313.09,216.98h1.52c.07,1.15,1.14,1.98,2.99,1.98s2.99-.87,2.99-2.02c0-1.3-1.4-1.46-2.99-1.65-1.98-.24-4.24-.54-4.24-2.91,0-2.02,1.63-3.39,4.21-3.39s4.17,1.39,4.22,3.21h-1.47c-.07-1.06-1.07-1.89-2.77-1.89s-2.7.83-2.7,1.93c0,1.32,1.43,1.48,2.99,1.67,1.72.22,4.26.48,4.26,2.91,0,2.04-1.76,3.45-4.5,3.45s-4.44-1.41-4.5-3.3"/>
                    <path className="st1" d="M333.42,214.64c0-2.48-1.78-4.3-4.42-4.3s-4.41,1.82-4.41,4.3,1.78,4.3,4.41,4.3,4.42-1.82,4.42-4.3M323.07,214.7c0-3.34,2.45-5.71,5.93-5.71s5.91,2.32,5.91,5.71v5.3h-1.38v-2.65c-.78,1.91-2.61,2.93-4.75,2.93-3.1,0-5.71-2.15-5.71-5.58"/>
                  </g>
                </g>
                {
                  lots && lots.map((lot, i) => {
                    const planClass = lot.plan ? 'plan-'+lot.plan : ''
                    const elevationClass = lot.plan ? 'show-elevation' : ''
                    let activeClass = '';
                    const center = setCenter(lot.shape_polygon);
                    const numberCenterX = lot.elevation_right ? center.x - 3 : center.x;
                    const numberCenterY = lot.elevation_right ? center.y : center.y -2;
                    const elevationCenterX = lot.elevation_right ? center.x + 6 : center.x;
                    const elevationCenterY = lot.elevation_right ? center.y : center.y + 5;
                    // console.log('center: ', center);

                    if (lot.neighborhood === 'laurel') {
                      if (
                        (laurelStatus !== lot.status && laurelStatus !== 'view-all' && laurelStatus !== 'status')
                      ) {
                        activeClass = 'hide';
                      }
                      if (
                        (laurelRelease !== lot.release && laurelRelease !== 'view-all' && laurelRelease !== 'release')
                      ) {
                        activeClass = 'hide';
                      }
                    } else {
                      if (
                        (mariposaStatus !== lot.status && mariposaStatus !== 'view-all' && mariposaStatus !== 'status')
                      ) {
                        activeClass = 'hide';
                      }
                      if (
                        (mariposaRelease !== lot.release && mariposaRelease !== 'view-all' && mariposaRelease !== 'release')
                      ) {
                        activeClass = 'hide';
                      }
                    }
                    return (
                      <g key={i} className={`${lot.neighborhood} ${planClass} ${elevationClass} ${activeClass}`} datastatus={lot.status} datasrelease={lot.release}>
                        {/* <g dangerouslySetInnerHTML={{__html: lot.svg_code}}></g> */}
                        <polygon className="" points={lot.shape_polygon}/>
                        <text x={numberCenterX} y={numberCenterY} textAnchor="middle" alignmentBaseline="middle" >{lot.number}</text>
                        <text x={elevationCenterX} y={elevationCenterY} textAnchor="middle" alignmentBaseline="middle" >{lot.elevation}</text>
                      </g>
                    )
                  })
                }
              </svg>
              <div className='secondary-legend legend-block'>
                <div className='legend-item d-flex align-items-center mb-2'>
                  <svg id="sales-office" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.22 16.22">
                    <path class="cls-1" d="M16.22,8.11c0,4.48-3.63,8.11-8.11,8.11S0,12.59,0,8.11,3.63,0,8.11,0s8.11,3.63,8.11,8.11"/>
                    <polygon class="cls-2" points="8 2.32 9.77 5.9 13.72 6.47 10.86 9.26 11.53 13.19 8 11.33 4.47 13.19 5.14 9.26 2.29 6.47 6.24 5.9 8 2.32"/>
                  </svg>
                  Sales Office
                </div>
                <div className='legend-item d-flex align-items-center mb-2'>
                  <svg id="models" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.65 15.65">
                    <path class="cls-3" d="M7.98,15.65c4.31-.08,7.76-3.66,7.67-7.97C15.57,3.37,11.99-.08,7.68,0,3.36.08-.08,3.66,0,7.98c.08,4.31,3.66,7.76,7.97,7.68Z"/>
                    <path class="cls-2" d="M7.95,8.17l2.53-4.96h1.75v9.1h-1.77v-5.64l-1.98,4.01h-1.12l-1.95-3.97v5.6h-1.75V3.21h1.73l2.56,4.96Z"/>
                  </svg>
                  Models
                </div>
                <div className='other-res legend-item d-flex align-items-center mb-2'><span className='color-block' style={{backgroundColor: `#f4e6d4`}}></span> Other Residential<br/>Under Construction</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu currentPage='site-map'/>
    </div>
  )
}

export default SiteMap
