import React from 'react'


import Menu from '../../components/Menu';

import mainImg from '../../img/around-here/around-here.jpg'

import './index.scss'

function AroundHere() {

  return (
    <div id='around-here' className='main-wrap'>
      <div className='internal-wrap container' style={{backgroundImage: "url(" + mainImg + ")"}}></div>
      <Menu currentPage='around-here'/>
    </div>
  )
}

export default AroundHere
