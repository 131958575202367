import React from 'react'

import SideMenu from '../../components/SideMenu'

import './index.scss'

function SingleFloorPlan(props) {
  // console.log('single plan props: ', props);
  // const titleArr = props.plan.title.split(' ');

  return (
    <div id='single-floor-plan'>
      <div className='row m-0 h-100 align-items-end align-items-xl-center'>
        <div className='floor-plan-wrap col-auto h-100'>
          <div className='row h-100'>
            <div className='elevations col-md-3 h-100 d-flex flex-column justify-content-center'>
                {
                  props.plan.elevations.map((item, i) => 
                    { return (
                      <div key={i} className={`elevation ${i === 0 ? '' : 'mt-3'}`}>
                        <img className='w-100' src={item.img} alt={item.title}/>
                        <div className='caption'>{item.title}</div>
                      </div>
                    )
                      
                    }
                  )
                }
            </div>
            <div className='floorplans h-100 col d-flex justify-content-evenly align-items-end px-3 py-3'>
                {
                  props.plan.floorPlans.map((item, i) => 
                    { return (
                      <img key={i} className='floorplan' src={item} alt={props.plan.title}/>
                    )
                      
                    }
                  )
                }
            </div>
          </div>
        </div>
        <div className='side-menu-wrap col-auto h-100 p-0'>
          <SideMenu plan={props.plan} />
        </div>
      </div>
      {/* <div className='title'>{titleArr[0]} <span>{titleArr[1]}{titleArr[2] ? ` ${titleArr[2]}` : null}</span></div>
      <div className='row h-100'>
        <div className='details-box col-md-3 col-xl-2 h-100'>
          <div className='details'>
            Approx. {props.plan.sqft} Sq. Ft.<br/>
            {props.plan.beds} Bedrooms<br/>
            {props.plan.baths} Bathrooms<br/>
            {props.plan.garage}
          </div>
        </div>
        <div className='floor-plan-images col-md-9 col-xl-10 d-flex justify-content-center align-items-center'>
          {
            props.plan.floorPlans.map((item, i) => {
              return <div key={i} className='floor-plan-img d-flex h-100 mx-3'><img src={item} alt={`${props.plan.title} floor plans`}/></div>
            })
          }
        </div>
      </div> */}
    </div>
  )
}

export default SingleFloorPlan
